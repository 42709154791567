import React from 'react'
import * as styles from './callout.module.css'

export default class Callout extends React.Component {
  renderTitle() {
    if (this.props.title) return <h3>{this.props.title}</h3>
    else if (this.props.renderTitle) return this.props.renderTitle()
    else return null
  }

  render() {
    if (this.props.renderContent)
      return (
        <aside className={`${this.props.className} ${styles.callout}`}>
          {this.props.renderContent()}
        </aside>
      )
    else
      return (
        <aside className={`${this.props.className} ${styles.callout}`}>
          {this.renderTitle()}
          {this.props.children}
          <footer className={styles.controls}>
            {this.props.renderActions && this.props.renderActions()}
          </footer>
        </aside>
      )
  }
}

export class MailListCallout extends React.Component {
  renderActions = () => {
    return (
      <a
        className={`${styles.action} ${styles.subscribe}`}
        href="http://eepurl.com/bR8FHb"
        target="_blank"
      >
        Sign me up!
      </a>
    )
  }

  render() {
    return (
      <Callout title="Enjoyed this Article?" renderActions={this.renderActions}>
        <p>
          Would you like to receive more articles like this one on programming,
          web development, JavaScript, Angular, developer productivity, tools,
          UX and even exclusive content like free versions of my books in your
          mailbox? Then{' '}
          <a href="http://eepurl.com/bR8FHb">
            sign up to my super duper awesome inner circle
          </a>
          .
        </p>
      </Callout>
    )
  }
}

export class TweetCallout extends React.Component {
  renderContent() {
    return (
      <section>
        <h4>Wop! Want to Reach Out?</h4>
        <p style={{ marginBottom: '0' }}>
          If you have any question or comments about the article just{' '}
          <a href="https://www.twitter.com/vintharas" target="blank_">
            reach out on the twitterverse!
          </a>{' '}
          My DMs are open and I'm always happy to help out and answer questions.
        </p>
      </section>
    )
  }
  render() {
    return (
      <Callout className={styles.info} renderContent={this.renderContent} />
    )
  }
}

export class BlueCallout extends React.Component {
  render() {
    return (
      <Callout className={styles.blue} {...this.props}>
        {this.props.children}
      </Callout>
    )
  }
}

export class InfoCallout extends React.Component {
  render() {
    return (
      <Callout className={styles.info} {...this.props}>
        {this.props.children}
      </Callout>
    )
  }
}
