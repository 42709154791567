import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/layout/Layout'
import Bio from '../components/bio/Bio'
import { InfoCallout, TweetCallout } from '../components/callout/Callout'
import Tag from '../components/tags/Tag'
import Newsletter from '../components/newsletter/Newsletter'
import * as styles from './BlogPost.module.css'
import { getFormattedDate } from '../utils/formatting'

import { rhythm } from '../utils/typography'

class MdxPageTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { mdx } = data
    const siteTitle = data.site.siteMetadata.title

    const { date, categories = [], description, title } = mdx.frontmatter
    const formattedDate = getFormattedDate(date)
    const [mainCategory] = categories || []

    // TODO(vintharas): Add cover image to mdx pages too
    return (
      <Layout>
        <article>
          <article className={styles.article}>
            <SEO title={`${title} | ${siteTitle}`} description={description} />

            <header className={styles.header}>
              <time className={styles.date}>{formattedDate}</time>
              {mainCategory && (
                <Tag style={{ float: 'right' }} small>
                  {mainCategory}
                </Tag>
              )}
              <h1 style={{ paddingBottom: rhythm(1) }}>{title}</h1>
            </header>
            <div className={styles.content}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </div>
            <hr style={{ marginBottom: rhythm(1) }} />
            <Bio />
            <hr style={{ marginBottom: rhythm(1) }} />
            <TweetCallout />
            {categories && (
              <InfoCallout
                style={{ marginBottom: rhythm(1) }}
                title="Tagged as..."
              >
                {categories.map((cat, idx) => (
                  <Tag key={idx}>{cat}</Tag>
                ))}
              </InfoCallout>
            )}
            <Newsletter
              theme="greenish"
              style={{
                boxShadow: '0px 1px 3px grey',
              }}
            />
          </article>
        </article>
      </Layout>
    )
  }
}

export default MdxPageTemplate

export const pageQuery = graphql`
  query MdxPageQuery($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
        categories
        description
      }
    }
  }
`
